
import AccordionHeaderComponent from "./AccordionHeaderComponent";
import {useState} from "react";
import ProDescriptionsComponent from "../ProDescriptionsComponent";
import {KeywordsComponent} from "../KeywordsComponent";
import {ShippingAndVariationsComponent} from "../ShippingAndVariationsComponent";
import {CustomizationsComponent} from "../CustomizationsComponent";


const DropdownComponent = ({onlyPro, hasBusiness, hasSimple, optionsAssignee,setOptionsAssignee, selectedOptionAssignee, setSelectedOptionAssignee, subAccounts,setSubAccounts, assigneeId, addMoreInputs, setAddMoreInputs,moreMeasurementsPart1, setMoreMeasurementsPart1,moreMeasurementsPart2, setMoreMeasurementsPart2, setFormInfo, variations, setVariations, bulletItems, setBulletItems, measureItems, setMeasureItems, measureBothItems, setMeasureBothItems, handleTagifiesChange, handleStandartInputChange, editIndex, formInfo, unescapedRowMetadata, listInfo, itemData, setItemData }:any) => {
	const [activeIndices, setActiveIndices] = useState<number[]>([]);

	return(
	 <div>
		 {
			( !hasSimple && !hasBusiness) &&
		  (
		    <>
			   <AccordionHeaderComponent activeIndices={activeIndices} setActiveIndices={setActiveIndices} accIndex={1} accTitle="Pro Description (Optional)" />
			   {activeIndices.includes(1) && <ProDescriptionsComponent itemData={itemData} editIndex={editIndex} setItemData={setItemData} setFormInfo={setFormInfo} addMoreInputs={addMoreInputs} setAddMoreInputs={setAddMoreInputs} moreMeasurementsPart1={moreMeasurementsPart1} setMoreMeasurementsPart1={setMoreMeasurementsPart1} moreMeasurementsPart2={moreMeasurementsPart2} setMoreMeasurementsPart2={setMoreMeasurementsPart2} handleStandartInputChange={handleStandartInputChange} formInfo={formInfo} unescapedRowMetadata={unescapedRowMetadata}/>}
		    </>
		  )
		 }

		 <AccordionHeaderComponent activeIndices={activeIndices} setActiveIndices={setActiveIndices} accIndex={2} accTitle="Keywords, Pricing, Quantity, SKU, UPC, COGS" />
		 {activeIndices.includes(2) && <KeywordsComponent hasSimple={hasSimple} formInfo={formInfo} unescapedRowMetadata={unescapedRowMetadata} handleTagifiesChange={handleTagifiesChange} handleStandartInputChange={handleStandartInputChange}/>}
		 {
		  ( !hasSimple && !hasBusiness) &&
		  (
		   <>
			   <AccordionHeaderComponent activeIndices={activeIndices} setActiveIndices={setActiveIndices} accIndex={3} accTitle="Shipping and Variations" />
			   {activeIndices.includes(3) && <ShippingAndVariationsComponent variations={variations} setVariations={setVariations} listInfo={listInfo} handleStandartInputChange={handleStandartInputChange} itemData={itemData} setItemData={setItemData} editIndex={editIndex} formInfo={formInfo} unescapedRowMetadata={unescapedRowMetadata} />}
			   <AccordionHeaderComponent activeIndices={activeIndices} setActiveIndices={setActiveIndices} accIndex={5} accTitle="Customizations" />
			   {activeIndices.includes(5) && <CustomizationsComponent onlyPro={onlyPro} assigneeId={assigneeId} setFormInfo={setFormInfo} formInfo={formInfo}  subAccounts={subAccounts} handleStandartInputChange={handleStandartInputChange} itemData={itemData} setItemData={setItemData} editIndex={editIndex} listInfo={listInfo} unescapedRowMetadata={unescapedRowMetadata}  bulletItems={bulletItems} setBulletItems={setBulletItems} measureItems={measureItems} setMeasureItems={setMeasureItems} measureBothItems={measureBothItems} setMeasureBothItems={setMeasureBothItems} optionsAssignee={optionsAssignee} setOptionsAssignee={setOptionsAssignee} selectedOptionAssignee={selectedOptionAssignee} setSelectedOptionAssignee={setSelectedOptionAssignee}/>}
		   </>
		  )
		 }
	 </div>
	)
}

export default DropdownComponent;