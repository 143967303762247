import styles from "./FooterComponent.module.scss";
import Select from "react-select";
import React, {useEffect, useState} from "react";

export const FooterComponent = ({savingProgress, showEditAddModal, disableSaveButtons, editId, handleAddListing, forTabletSize, enabledButtons, handleSaveForm, setSaveAndClose, setSaveAndPreview, setSaveAndNew} :any)=>{
    const FooterButtonsOptions =  [
        { value: 'save_progress', label: 'Save Progress' },
        { value: 'save_and_new', label: 'Save and New' },
        { value: 'save_and_preview', label: 'Save and Preview' },
        { value: 'save_and_close', label: 'Save and Close' }
    ]
    const selectedFromStorage =  localStorage.getItem('selectedOptionSave');
    const [selectedOptionFooter, setSelectedOptionFooter] = useState<any>(selectedFromStorage ? JSON.parse(selectedFromStorage) : FooterButtonsOptions[3]);

    const customStylesFooter = {
        control: (provided:any, state:any) => ({
            ...provided,
            height: '40px',
            borderRadius: '0 8px 8px 0',
            cursor:'pointer',
            maxWidth: '100px',
        }),
        container: (provided: any, state: any) => ({
            ...provided,
            fontSize: '14px'
        }),
        menu: (provided: any) => ({
            ...provided,
            maxWidth: '140px',
            minWidth: '140px',
            fontSize: '14px',
            right: 0,
            left: 'auto',
        }),
    };

    const handleSelectChange = (selectedOptionFooter:any) => {
        setSelectedOptionFooter(selectedOptionFooter);
        localStorage.setItem('selectedOptionSave', JSON.stringify(selectedOptionFooter));
    };

    const handleFooterSaveButtons = (value:any)=>{
        switch (value){
            case 'save_progress':
                editId ? handleSaveForm() : handleAddListing()
                break;
            case 'save_and_new':
                setSaveAndNew(true);
                editId ? handleSaveForm() : handleAddListing()
                break;
            case 'save_and_preview':
                setSaveAndPreview(true);
                editId ? handleSaveForm() : handleAddListing()
                break;
            case 'save_and_close':
                setSaveAndClose(true);
                editId ? handleSaveForm() : handleAddListing()
                break;
            default:
        }
    }

    return (
        <div className={`${styles.footerButtonsMobile} ${forTabletSize ? styles.footerButtonsTablet : styles.fixedFooterMobile} ${editId && showEditAddModal ? styles.footerButtonsMobileOnModal : ''}`}>
            <Select
                options={FooterButtonsOptions}
                onChange={handleSelectChange}
                defaultValue={selectedOptionFooter[0]}
                placeholder={'Options'}
                menuPlacement={forTabletSize ? "bottom" : 'top'}
                styles={customStylesFooter}
                isSearchable={false}
            />
            {selectedOptionFooter && (
                <button
                    onClick={() => {
                        if(enabledButtons && !disableSaveButtons){
                            handleFooterSaveButtons(selectedOptionFooter.value)
                        }
                    }}
                    style={!enabledButtons || disableSaveButtons || savingProgress  ? {opacity: 0.7} : {opacity: 1}}
                    disabled={!enabledButtons || disableSaveButtons || savingProgress }
                >
                    {selectedOptionFooter.label}
                </button>
            )}
        </div>
    )
}